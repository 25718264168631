import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import ChevronUpIcon from '@heroicons/react/24/outline/ChevronUpIcon'
import { Disclosure, Transition } from '@headlessui/react'
import i18n from './i18n'
import { track } from 'segment'
import { OrganizationBasic, OrganizationProduct, OrganizationSettings, getOrganizationProductDescription } from 'models/organization'
import { use } from 'i18next'

type PropsIn = {
  title: string,
  subtitle?: string,
  content: React.ReactNode,
  v2?: boolean,
  defaultOpen?: boolean,
  disabled?: boolean,
  titleClassName?: string,
  subtitleClassName?: string,
  avatar?: React.ReactNode,
  organization?: OrganizationBasic&OrganizationSettings,
}

export default function disclosure(props: PropsIn) {
  const divCn = clsx({
    'w-full': true,
    'bg-gray-50 border border-gray-300 rounded-md': props.v2,
    'bg-white': !props.v2,
  })

  return (
    <div className={divCn}>
      <Disclosure defaultOpen={props.defaultOpen}>
        {({ open }) => {
          const disclosureCn = clsx({
            'w-full flex justify-between items-center px-4 py-3 text-sm font-medium text-left text-medgray rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75': true,
            'bg-gray-50': props.v2,
            'bg-white hover:bg-gray-50': !props.v2,
            'hover:bg-gray-100': props.v2 && !open,
          })

          const shouldTrack = ['Add-ons Usage', 'Disabled/Enabled users count']
          if (open && !props.defaultOpen && props.title && shouldTrack.includes(props.title)) {
            track({
              event: `Data Reporting - ${props.title} Clicked`,
              variables: {
                workspaceId: props?.organization?.id,
                platform: 'dashboard',
                workspaceProductId: props?.organization?.product,
                workspaceProductName: getOrganizationProductDescription(props?.organization?.product as OrganizationProduct) ?? '',
                workspacePlanId: props?.organization?.billingService?.planId,
                workspacePlanName: props?.organization?.billingService?.planName,
                workspaceName: props?.organization?.name,
              },
            })
          }

          return (
            <>
              <Disclosure.Button disabled={props.disabled} className={disclosureCn}>
                {props.avatar && <div>{props.avatar}</div> }
                <div className="flex-1 flex flex-col items-start truncate">
                  <div className={props.titleClassName || 'font-semibold text-sm leading-6'}>{props.title}</div>
                  <div className={props.subtitleClassName || 'text-medgray font-normal text-sm'}>
                    {props.subtitle || `${i18n.t('expand_to_view_details')}`}
                  </div>
                </div>

                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-gray-500 z-0` }
                />
              </Disclosure.Button>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0">
                <Disclosure.Panel className="px-4 py-3 text-sm text-gray-500">
                  {props.content}
                </Disclosure.Panel>
              </Transition>
            </>
          )
        }}
      </Disclosure>
    </div>
  )
}
