import { useQuery } from '@apollo/client'
import add from 'date-fns/add'
import format from 'date-fns/format'
import isWeekend from 'date-fns/isWeekend'
import { produce } from 'immer'
import { Study } from 'models/study'
import { UserRoles } from 'models/user'
import { isEmpty, range, subtract } from 'ramda'
import { getGnowbeApiToken, logoutHandler } from './auth'
import CLIENT_SETTINGS from './client_settings'

export function getDaysFromEpoch(date: Date) {
  const millis = date.getTime()
  return Math.floor(millis / 3600 / 24 / 1000)
}

export function getDateFromDaysFromEpoch(daysFromEpoch: number) {
  const millis = daysFromEpoch * 3600 * 24 * 1000
  return new Date(millis)
}

export function workingDaysPassed(todayDay, beforeDay, timezone) {
  const daysPass = todayDay - beforeDay
  let weekDaysPass = 0
  range(0, daysPass).forEach((day) => {
    const now = new Date()
    const currentDate = subtract(add(now, timezone || 0).getTime(), day)
    if (!isWeekend(currentDate)) {
      weekDaysPass = weekDaysPass + 1
    }
  })
  return weekDaysPass
}

export function getTimeZonesForLocalHour(localHour) { // http://www.timeanddate.com/time/map/
  const utcHour = new Date().getUTCHours()
  let timezone = localHour - utcHour
  if (timezone < -11) {
    timezone = 24 + timezone
  }
  const timezones = [timezone]
  if (timezone < -9) {
    timezones.push(24 + timezone)
  }
  return timezones
}

export function getNumberToLocaleString(num: number) {
  const lang = navigator.language || 'us'
  return Number(num).toLocaleString(lang)
}

export function stripHashtags(text: string) {
  return text ? text.split(' ').filter(item => item[0] !== '#').join(' ') : text
}

export function escapeStrForRegExp(str) {
  if (!str) return str
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
}

export function throttle<T extends (...args: any[]) => any>(func: T, wait: number): T {
  // tslint:disable-next-line: one-variable-per-declaration
  let ctx, args, rtn, timeoutID // caching
  let last = 0

  const call = function () {
    timeoutID = 0
    last = Date.now()
    rtn = func.apply(ctx, args)
    ctx = null
    args = null
  }

  const throttled = function () {
    ctx = this
    args = arguments
    const delta = Date.now() - last
    if (!timeoutID) {
      if (delta >= wait) call()
      else timeoutID = setTimeout(call, wait - delta)
    }
    return rtn
  }

  return throttled as any
}

export function parseUrl(url, whole = false) {
  if (whole) {
    // tslint:disable-next-line: no-parameter-reassignment
    url = url.substring(url.indexOf('?'))
  }
  const query: any = {}
  url.substring(1).split('&').map((e) => {
    const el = e.split('=')
    query[el[0]] = el[1]
  })
  return query as {[field: string]: any}
}

class ResponseError extends Error {
  constructor(public statusCode, message: string, public error: any) {
    super(message)
  }
}

export function checkHttpStatus(response, allow4xx = false, skipUnauthorizedCheck = false) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  if (!skipUnauthorizedCheck && response.status === 401) {
    logoutHandler()
    throw new Error('Unauthorized')
  }

  if (allow4xx && response.status >= 400 && response.status < 500) {
    return response
  }

  return new Promise((res, rej) => {
    response.json().then((json) => {
      rej(new ResponseError(response.status, response.statusText, json.error))
    })
  })
}

export function formatDate(date: number|null) {
  if (!date) return null
  return format(date, 'MMM dd, yyyy')
}

export function formatDateTime(date: number | null) {
  if (!date) return null;
  return format(date, 'MMM dd, yyyy HH:mm');
}

export function formatDateTimeBoth(date: number | null): string | null {
  if (!date || date === 999) return null

  const localDate = new Date(date)

  // Create a UTC date object by adjusting for the timezone offset
  const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000)

  const localFormatted = format(localDate, 'MMM dd, yyyy HH:mm')
  const utcFormatted = format(utcDate, 'MMM dd, yyyy HH:mm')

  return `<p class="text-center text-xs">${localFormatted}</p><p class="text-center text-xs">(UTC: ${utcFormatted})</p>`
}

export function formatDateToDay(date: number) {
  return format(date, 'MMM dd, yyyy')
}

export function copyToClipboard(val: string) {
  const copyText = val
  if (navigator.clipboard) {
    navigator.clipboard.writeText(copyText).then(
      () => null,
      (error) => {
        console.log(error)
      },
    )
  } else {
    console.log(document.execCommand('copy'))
    document.execCommand('copy')
  }
}

export const randHex = function (len) {
  const maxlen  = 8
  const min     = Math.pow(16, Math.min(len, maxlen) - 1)
  const max     = Math.pow(16, Math.min(len, maxlen)) - 1
  const n       = Math.floor(Math.random() * (max - min + 1)) + min
  let   r       = n.toString(16)
  while (r.length < len) {
    r = r + randHex(len - maxlen)
  }
  return r
}

export const hexToRgb = function (hex) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hexTmp = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexTmp)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null
}

export function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${x}`)
}


/** Removes attribute from object and all of its childs */
export function removePropDeep<T extends object, TKey extends string>(key: TKey, obj: T): Omit<T, TKey> {
  return produce(obj, (draft) => {
    const inner = (obj) => {
      for (const prop in obj) {
        if (prop === key) delete obj[prop]
        else if (Array.isArray(obj[prop])) {
          obj[prop] = obj[prop].filter((k) => {
            return !isEmpty(inner(k))
          })
        } else if (typeof obj[prop] === 'object') {
          inner(obj[prop])
        }
      }
      return obj
    }
    inner(draft)
  })
}

export const getStringDateFormat = () => {
  return 'MMM d, yyyy'
}

export const parseNumberToHourFormat = (num: number) => {
  return `${Math.floor(num)
    .toLocaleString('en-US', {
      minimumIntegerDigits: 2, useGrouping: false,
    })}:${(Math.abs(Math.floor(num) - num) * 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2, useGrouping: false,
    })}`
}

const signaturesMap = new Map<string, {
  queryString: string,
  expiresAt: number,
}>()

function storageFolderRootToType(folderRoot: string) {
  const type =
    folderRoot === 'crs' ? 'course' :
    folderRoot === 'usr' ? 'user' :
    folderRoot === 'sub' ? 'subscription' :
    folderRoot === 'org' ? 'organization' :
    folderRoot === 'cmp' ? 'company' :
    folderRoot === 'bnd' ? 'bundle' :
    folderRoot === 'imports' ? 'imports' :
    folderRoot === 'exports' ? 'exports' :
    folderRoot === 'other' ? 'other'
    : ''
  return type
}

export const getSignedValue = (unsignedValue: string) => {
  if (!unsignedValue) return unsignedValue

  // https://cdn1.staging.sandbox.gnowbe.com/crs/EajwcE2Fic5SnWoXn/pub/gnb-prc-830x0_1DmAbtpy2P83Hys
  try {
    const url = new URL(unsignedValue)

    // Signatures only on CDN urls
    // Same logic is in sw.js - isGnowbeCdnFile!
    if (!/gnowbe\.com/.test(url.host)) {
      return unsignedValue
    }
    if (!/^cdn1/.test(url.host)) {
      return unsignedValue
    }
    // No need for signatures for public files
    if (/\/pub\//.test(url.pathname)) {
      return unsignedValue
    }

    // /usr/H52nHqXfH3fomzcLj/gnb-prc-830x0_x7sxovRH7mtrh7P
    const pathParts = url.pathname.split('/').filter(i => i)
    if (pathParts.length !== 3) {
      return unsignedValue
    }
    const type = storageFolderRootToType(pathParts[0])
    if (!type) {
      return unsignedValue
    }
    const key = pathParts[1]

    const signature = signaturesMap.get(`${type}:${key}`)

    if (!signature || signature.expiresAt < Date.now()) {
      // TODO: should we return "loading" image
      return unsignedValue
    }

    const fixedQueryString = unsignedValue.includes('?')
      ? `&${signature.queryString.substr(1)}`
      : signature.queryString
    const signedValue = unsignedValue + fixedQueryString

    return signedValue
  } catch (err) {
    return unsignedValue
  }
}

export function stripHtml(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent || ''
}

export function truncate(text: string, length?: number) {
  const newLength = length || 100
  if (text.length > newLength) {
    return `${text.slice(0, newLength - 3)}...`
  }
  return text
}

export const augmentExplain = (text: string|undefined) => {
  if (!text) return ''

  let result = text
  const matches = (text.match(/{{explain:.*?}}/gi) || [])
  matches.forEach((match) => {
    const tuple = match.replace(/[{}]/gi, '').split(':')
    const [source, text, popupText, imageUrl] = tuple
    // if you hover over an explanation fast enough,
    // an instance will stay in the dom because the id changes (react rerenders 3 times)!
    const randomId = Math.random().toString(36).slice(-6)
    let popupTextEscaped = stripHtml(decodeURIComponent(popupText))
    if (popupTextEscaped) {
      popupTextEscaped = popupTextEscaped.replace(/"/g, '&quot;')
    }
    result = result.replace(match, `<abbr class="explainer" data-id="${randomId}" data-content="${popupTextEscaped}"${imageUrl && ` data-image="${getSignedValue(decodeURIComponent(imageUrl))}"` || ''}>${decodeURIComponent(text)}</abbr>`)
  })
  return result
}

export function augmentStudyAnswerRef(
  deps: {
    getStudy: (chapterId: string, actionId: string) => Pick<Study, 'chapterId'|'actionId'|'answer'>|undefined,
    getChapterOrder: (chapterId: string) => number|undefined,
  },
  text: string,
) {
  if (!text) return ''

  return text.replace(/{{user:(qa|answer):([^\:\}]+?):([^\:\}]+?)}}/gi, (substr) => {
    const [src, prop, chapterId, actionId] = substr.replace(/[{}]/gi, '').split(':')
    if (src === 'user' && (prop === 'answer' || prop === 'qa')) {
      const study = deps.getStudy(chapterId, actionId)

      if (study?.answer) {
        return study.answer
      }

      const chapterOrder = deps.getChapterOrder(chapterId)
      return chapterOrder
        ? `[please complete your answer in session ${chapterOrder}]`
        : '[this answer was copied from an unknown session]'
    }

    return substr
  })
}

export function isWordPuzzleCorrect(placeholderAnswer, answerIn) {
  if (!placeholderAnswer || !answerIn) return false
  const answer = answerIn.toUpperCase()
  return placeholderAnswer.toUpperCase().split('').every(c => c === ' ' || answer.includes(c))
}

export const useImperativeQuery = (query) => {
  const { refetch } = useQuery(query, { skip: true })

  const imperativelyCallQuery = (variables) => {
    return refetch(variables)
  }

  return imperativelyCallQuery
}

export const addScript = (path: string, id?: string, callback?: Function) => {
  const script = document.createElement('script')
  script.src = path
  script.type = 'text/javascript'
  if (id) {
    script.id = id
  }
  script.async = true
  script.onload = (ev) => {
    if (callback) callback(ev)
  }
  document.body.appendChild(script)
}

export const removeScript = (path: string, id?: string) => {
  if (id) {
    const el = document.getElementById(id)
    if (el) {
      el.remove()
      return
    }
  }
  const allsuspects = document.getElementsByTagName('script')
  if (!allsuspects) return
  for (let i = allsuspects.length; i >= 0; i -= 1) {
    if (allsuspects[i] && allsuspects[i].getAttribute('src') !== null
      && allsuspects[i]?.getAttribute('src')?.indexOf(path) !== -1) {
      allsuspects[i]?.parentNode?.removeChild(allsuspects[i])
    }
  }
}

export const showUnlimitedText = (num: number|undefined) => {
  if (!num) return '0'
  if (num >= 1000000) return 'unlimited'
  return getNumberToLocaleString(num)
}

export const userRoles = (user: Pick<UserRoles, 'billingManagerOrganizations'|'creatorInOrganizations'|'memberOfOrganizations'>, currentWorkspaceId) => {
  let roles: String[] = []
  if (user.billingManagerOrganizations.includes(currentWorkspaceId)) roles = roles.concat(['billingManager'])
  if (user.creatorInOrganizations.includes(currentWorkspaceId)) roles = roles.concat(['creator'])
  if (user.memberOfOrganizations.includes(currentWorkspaceId)) roles = roles.concat(['learner'])
  return roles || []
}

export const invoiceNameWithoutDevelopedOrEmerging = (invoiceName: string | undefined) => {
  if (!invoiceName) return ''
  return invoiceName.replace(/\s*(developed|emerging|v5|developing|CU)\s*/gi, '')
}

export const formatNumberWithCommas = (number) => {
  if (isNaN(number)) {
    return 'Invalid number';
  }

  const numberString = number.toString()
  const parts = numberString.split('.')

  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  const formattedNumber = parts.length === 2 ? integerPart + '.' + parts[1] : integerPart

  return formattedNumber
}

export const capitalizeWord = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const getOrganizationLandingUrl = (customSubdomain?: string, organizationId?: string) => {
  const restOfDomain = process.env.BUILD === 'production' ? 'gnowbe.page' : 'sandbox.gnowbe.page'
  return `https://${(customSubdomain || organizationId)}.${restOfDomain}`
}

export const getGroupLandingUrl = (groupId: string, orgEnabled: boolean|undefined, customSubdomain?: string, organizationId?: string) => {
  const restOfDomain = process.env.BUILD === 'production' ? 'gnowbe.page' : 'sandbox.gnowbe.page'
  const domain = orgEnabled ? `${customSubdomain || organizationId}.${restOfDomain}` : restOfDomain
  return `https://${domain}/groups/${groupId}`
}

export const runBuildPublicCompany = async () => {
  // This function is used to build public company landing page
  // It is used in the company landing page settings
  const apiToken = await getGnowbeApiToken()
  const res = await fetch(
    CLIENT_SETTINGS.public.gnowbeApiUrl +
    `/api/v1/integrations/companies/landingPages/generate-pages/6f4e9b3d-12ac-84b7-9e5f-bf29ac7e3d6a`,
    {
      method: 'GET',
      headers: {
        'accept': 'application/json',
        'x-gnowbe-source': 'gnowbe-dashboard 1',
        'authorization': apiToken.tokenType + ' ' + apiToken.token
      } as any
    }
  )
  const data = await res.json()
  return data
}